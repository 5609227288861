import { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { useSnackbar } from "react-simple-snackbar";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import VerifiedEmail from "../components/VerifiedEmail";

const Settings = () => {
	const navigate = useNavigate();
	const [user, setUser] = useContext(UserContext);
	const [profile, setProfile] = useContext(ProfileContext);
	const [userProfile, setUserProfile] = useState(null);
	const [value, setValue] = useState(0);
	const [email, setEmail] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [nameModal, setNameModal] = useState(false);
	const [msisdnModal, setMsisdnModal] = useState(false);
	const [emailModal, setEmailModal] = useState(false);
	const [passwordResetModal, setPasswordResetModal] = useState(false);
	const [enableMfaPasswordModal, setEnableMfaPasswordModal] = useState(false);
	const [disableMfaPasswordModal, setDisableMfaPasswordModal] = useState(false);
	const [mfaPickerModal, setMfaPickerModal] = useState(false);
	const [mfaQrModal, setMfaQrModal] = useState(false);
	const [mfaCodeModal, setMfaCodeModal] = useState(false);
	const [mfaSuccessModal, setMfaSuccessModal] = useState(false);
	const [cancelModal, setCancelModal] = useState(false);
	const [deactivateModal, setDeactivateModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [resending, setResending] = useState(false);
	const [msisdnError, setMsisdnError] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastnameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [mfaType, setMfaType] = useState("");
	const [mfaLink, setMfaLink] = useState("");
	const [codeError, setCodeError] = useState("");
	const [confirmationCode, setConfirmationCode] = useState("");
	const [grandTotal, setGrandTotal] = useState(0);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [subscription, setSubscription] = useState(null);
	const [cancelPassword, setCancelPassword] = useState("");
	const [deactivatePassword, setDeactivatePassword] = useState("");
	const [deletePassword, setDeletePassword] = useState("");
	const [cancelPasswordError, setCancelPasswordError] = useState("");
	const [deactivatePasswordError, setDeactivatePasswordError] = useState("");
	const [deletePasswordError, setDeletePasswordError] = useState("");
	const [usernameModal, setUsernameModal] = useState(false);
	const [username, setUsername] = useState("");
	const [namePassword, setNamePassword] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [namePasswordError, setNamePasswordError] = useState("");
	const [unsubscribeModal, setUnsubscribeModal] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const CustomTabPanel = (props) => {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<div className="settings-tab-content">{children}</div>
				)}
			</div>
		);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const getData = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/settings`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUserProfile(data.user);
			setSubscription(data.subscription);
			setPaymentMethods(data.payment_methods);
		}
	};

	const cancelSubscription = async (e) => {
		setCancelPasswordError("");
		setActionLoading(true);
		e.preventDefault();
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				password: cancelPassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/subscriptions`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "password") {
				setCancelPasswordError(data.msg);
			} else {
				dangerSnackbar(data.msg);
			}
		} else {
			getData();
			setProfile(data.profile);
			setCancelModal(false);
			defaultSnackbar(data.msg);
		}
		setCancelPassword("");
		setActionLoading(false);
	};

	const deactivateAccount = async (e) => {
		e.preventDefault();
		setDeactivatePasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				password: deactivatePassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/deactivate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "password") {
				setDeactivatePasswordError(data.msg);
			} else {
				dangerSnackbar(data.msg);
			}
		} else {
			setUser(null);
		}
		setDeactivatePassword("");
		setActionLoading(false);
	};

	const deleteAccount = async (e) => {
		setDeletePasswordError("");
		setActionLoading(true);
		e.preventDefault();
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				password: deletePassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "password") {
				setDeletePasswordError(data.msg);
			} else {
				dangerSnackbar(data.msg);
			}
		} else {
			setUser(null);
		}
		setDeletePassword("");
		setActionLoading(false);
	};

	const resumeSubscription = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/subscriptions/resume`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setProfile(data.profile);
			defaultSnackbar(data.msg);
			getData();
		}
		setActionLoading(false);
	};

	const changeEmail = async (e) => {
		e.preventDefault();
		setEmailError("");
		setPasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				email: email,
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/email`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "email") {
				setEmailError(data.msg);
			} else {
				setPasswordError(data.msg);
			}
		} else {
			setEmail("");
			setEmailModal(false);
			defaultSnackbar(data.msg);
			getData();
		}
		setPassword("");
		setActionLoading(false);
	};

	const changeUsername = async (e) => {
		e.preventDefault();
		setUsernameError("");
		setNamePasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				username: username,
				password: namePassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/username`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "username") {
				setUsernameError(data.msg);
			} else {
				setNamePasswordError(data.msg);
			}
		} else {
			setUsername("");
			setUser(data.user_session);
			setUsernameModal(false);
			defaultSnackbar(data.msg);
			getData();
		}
		setNamePassword("");
		setActionLoading(false);
	};

	const changeName = async (e) => {
		e.preventDefault();
		setFirstNameError("");
		setLastnameError("");
		setActionLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				first_name: firstName,
				last_name: lastName,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/name`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setFirstName("");
			setLastName("");
			setUser(data.user_session);
			setNameModal(false);
			defaultSnackbar(data.msg);
			getData();
		}
		setNamePassword("");
		setActionLoading(false);
	};

	const changeMsisdn = async (e) => {
		e.preventDefault();
		setMsisdnError("");
		setActionLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				msisdn: msisdn,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/msisdn`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setMsisdn("");
			setUser(data.user_session);
			setMsisdnModal(false);
			defaultSnackbar(data.msg);
			getData();
		}
		setMsisdn("");
		setActionLoading(false);
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/password/generate`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setPasswordResetModal(false);
			defaultSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const confirmPasswordEnable = async (e) => {
		e.preventDefault();
		setPasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/password/confirm`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setPassword("");
			if (data.error == "password") {
				setPasswordError(data.msg);
			}
		} else {
			setPassword("");
			closeEnableMfaPasswordModal(false);
			setMfaPickerModal(true);
		}
		setActionLoading(false);
	};

	const confirmPasswordDisable = async (e) => {
		e.preventDefault();
		setPasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				password: password,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/mfa/disable`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			setPassword("");
			if (data.error == "password") {
				setPasswordError(data.msg);
			}
		} else {
			setPassword("");
			closeDisableMfaPasswordModal(false);
			defaultSnackbar(data.msg);
			getData();
		}
		setActionLoading(false);
	};

	const enableMfa = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/mfa/enable/${mfaType}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setMfaPickerModal(false);
			if (data.type === "email") {
				setMfaSuccessModal(true);
				getData();
			} else {
				setMfaLink(data.mfa_link);
				setMfaQrModal(true);
			}
		}
		setActionLoading(false);
	};

	const confirmCode = async (e) => {
		e.preventDefault();
		setCodeError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				code: confirmationCode,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/mfa/confirm`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "code") {
				setCodeError(data.msg);
			}
		} else {
			setConfirmationCode("");
			setMfaCodeModal(false);
			setMfaSuccessModal(true);
			getData();
		}
		setActionLoading(false);
	};

	const resendEmail = async () => {
		setResending(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/email/resend`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
		}
		setResending(false);
	};

	const closeEmailModal = () => {
		setEmail("");
		setPassword("");
		setEmailError("");
		setPasswordError("");
		setEmailModal(false);
	};

	const closeUsernameModal = () => {
		setUsername("");
		setNamePassword("");
		setUsernameError("");
		setNamePasswordError("");
		setUsernameModal(false);
	};

	const closeNameModal = () => {
		setFirstName("");
		setLastName("");
		setFirstNameError("");
		setLastnameError("");
		setNameModal(false);
	};

	const closeMsisdnModal = () => {
		setMsisdn("");
		setMsisdnError("");
		setMsisdnModal(false);
	};

	const closeCancelModal = () => {
		setCancelPassword("");
		setCancelPasswordError("");
		setCancelModal(false);
	};

	const closeDeactivateModal = () => {
		setDeactivatePassword("");
		setDeactivatePasswordError("");
		setDeactivateModal(false);
	};

	const closeDeleteModal = () => {
		setDeletePassword("");
		setDeletePasswordError("");
		setDeleteModal(false);
	};

	const closeEnableMfaPasswordModal = () => {
		setPassword("");
		setPasswordError("");
		setEnableMfaPasswordModal(false);
	};

	const closeDisableMfaPasswordModal = () => {
		setPassword("");
		setPasswordError("");
		setDisableMfaPasswordModal(false);
	};

	useEffect(() => {
		document.title = "Account Settings | SMSafe";
		getData();
	}, []);

	useEffect(() => {
		if (userProfile && userProfile.subscription) {
			let total = 0;
			userProfile.subscription.items.forEach((item) => {
				if (item.max_free) {
					if (item.max_free < item.quantity) {
						total += (item.quantity - item.max_free) * item.unit_amount;
					}
				} else {
					total += item.quantity * item.unit_amount;
				}
			});
			setGrandTotal(total);
		}
	}, [userProfile]);

	const handleSubscribe = () => {
		if (profile.payment_methods && profile.payment_methods.data.length !== 0) {
			resumeSubscription();
		} else {
			navigate("/plans", { state: { current_plan: profile.user.tier } });
		}
	};

	return (
		<>
			<div className="content full-page">
				<Container>
					<h1 className="settings-content-title text-lg text-medium-inter">
						Account Settings
					</h1>
					<Row>
						<Col className="page-content col-lg-8 col-12">
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="basic tabs example"
							>
								<Tab
									label="Account"
									className="settings-tab text-default text-sm"
								/>
								<Tab
									label="Two-Factor Authentication"
									className="settings-tab text-default text-sm"
								/>
								<Tab
									label="Billing"
									className="settings-tab text-default text-sm"
								/>
							</Tabs>
							<CustomTabPanel value={value} index={0} className="">
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Full Name
									</span>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="settings-email text-sm opacity-5">
												{userProfile?.first_name} {userProfile?.last_name}
											</span>
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Button
												className="settings-action-btn btn-sm btn-default text-sm"
												onClick={() => setNameModal(true)}
											>
												Change Name
											</Button>
										</Col>
									</Row>
								</div>
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Mobile No.
									</span>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="settings-email text-sm opacity-5">
												{userProfile?.msisdn}
											</span>
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Button
												className="settings-action-btn btn-sm btn-default text-sm"
												onClick={() => setMsisdnModal(true)}
											>
												Change Mobile No.
											</Button>
										</Col>
									</Row>
								</div>
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Email
									</span>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="settings-email text-sm opacity-5">
												{userProfile?.email}
											</span>
											{userProfile?.email_verified && <VerifiedEmail />}
										</Col>
										{/* <Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Button
												className="settings-action-btn btn-sm btn-default text-sm"
												onClick={() => setEmailModal(true)}
											>
												Change Email
											</Button>
										</Col> */}
									</Row>

									{userProfile && !userProfile.email_verified && (
										<div className="verify-instructions bg-orange-light flex-row-left rounded-4">
											<span className="text-bold-inter text-sm text-orange me-1">
												Verify your email:
											</span>
											<span className="text-sm text-orange">
												A verification link has been sent to your email.
											</span>
											<div className="verify-email-container flex-row-right">
												<a
													href="#"
													className="text-medium-inter text-sm"
													onClick={resendEmail}
												>
													{resending ? "Sending.." : "Resend Email"}
												</a>
											</div>
										</div>
									)}
								</div>
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Change Username
									</span>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="settings-email text-sm opacity-5">
												{userProfile?.username}
											</span>
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Button
												className="settings-action-btn btn-sm btn-default text-sm"
												onClick={() => setUsernameModal(true)}
											>
												Change Username
											</Button>
										</Col>
									</Row>
								</div>
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Change Password
									</span>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="text-sm opacity-5">
												Update your password here
											</span>
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Link to="/password/reset">
												<Button className="settings-action-btn btn-sm btn-default text-sm">
													Change Password
												</Button>
											</Link>
										</Col>
									</Row>
								</div>
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Account Management
									</span>
									<Row className="mb-20">
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="text-sm opacity-5">
												Sign back in anytime to reactivate your account
											</span>
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Button
												className="settings-action-btn btn-sm btn-default text-sm"
												onClick={() => setDeactivateModal(true)}
											>
												Deactivate Account
											</Button>
										</Col>
									</Row>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											<span className="text-sm opacity-5">
												Permanently delete your account and all of your content
											</span>
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											<Button
												className="settings-action-btn btn-sm btn-danger text-sm"
												onClick={() => setDeleteModal(true)}
											>
												Delete Account
											</Button>
										</Col>
									</Row>
								</div>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={1}>
								<div className="bg-white p-30 mb-15 rounded-4">
									<span className="text-lg mb-15 block text-medium-inter">
										Two-Factor Authentication
									</span>
									<Row>
										<Col className="col-lg-7 col-12 flex-row-left">
											{userProfile?.email_mfa_enabled ? (
												<span className="text-sm opacity-5 mb-0">
													Email enabled ({userProfile?.email})
												</span>
											) : userProfile?.mfa_enabled ? (
												<span className="text-sm opacity-5 mb-0">
													Authenticator app enabled
												</span>
											) : (
												<p className="text-sm opacity-5 mb-0">
													Add an extra layer of protection for accessing your
													SMSafe account.
												</p>
											)}
										</Col>
										<Col className="settings-action col-lg-5 col-12 flex-row-right">
											{userProfile?.email_mfa_enabled ||
											userProfile?.mfa_enabled ? (
												<Button
													className="settings-action-btn btn-sm btn-danger text-sm"
													onClick={() => setDisableMfaPasswordModal(true)}
												>
													Disable
												</Button>
											) : (
												<Button
													className="settings-action-btn btn-sm btn-default text-sm"
													onClick={() => setEnableMfaPasswordModal(true)}
												>
													Enable
												</Button>
											)}
										</Col>
									</Row>
								</div>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={2}>
								<div className="rounded-4 bg-white mb-30">
									<div className="settings-tier-container p-20 bg-default rounded-4">
										<Row className="p-20">
											<Col className="col-lg-5 col-12">
												<span className="text-white text-sm block">
													You're currently on
												</span>
												<span className="text-medium-inter title-md text-white">
													SMSafe {userProfile?.tier}
												</span>
											</Col>
											<Col className="plan-switcher-btns col-lg-7 col-12 flex-row-right">
												<a href="/plans">
													<Button variant="outline-light" className="btn-sm">
														Switch Plans
													</Button>
												</a>
												{userProfile?.tier !== "Free" && (
													<Button
														variant="danger"
														className="btn-sm ms-2"
														onClick={() => setUnsubscribeModal(true)}
													>
														Unsubscribe
													</Button>
												)}
											</Col>
										</Row>
									</div>
								</div>
								<div className="rounded-4 bg-white mb-30">
									{userProfile?.subscription &&
										userProfile?.subscription.status === "active" && (
											<div className="p-20 border-bottom">
												<div className="mb-20">
													<span className="text-medium-inter text-lg">
														Estimated Bill
													</span>
													<p className="opacity-5 text-sm m-0">
														This is an estimate of the amount you owe based on
														your current usage for this billing period.
													</p>
												</div>
												<div>
													<span className="title-md">
														${grandTotal.toFixed(2)}
													</span>
												</div>
											</div>
										)}
									{userProfile?.subscription &&
										userProfile?.subscription.status === "active" && (
											<Row className="p-20">
												<Col className="col-lg-6 col-12">
													<span className="text-sm opacity-5 block">
														Due Date
													</span>

													{userProfile?.subscription &&
														userProfile?.subscription.status === "active" && (
															<span className="text-sm block">
																{format(
																	new Date(
																		subscription?.current_period_end * 1000
																	),
																	"MMMM dd, yyyy"
																)}
															</span>
														)}
												</Col>
												{/* <Col className="settings-action col-lg-6 col-12 flex-row-right">
											{userProfile?.subscription &&
											userProfile?.subscription.status === "active" ? (
												<Button
													className="btn-danger btn-sm"
													onClick={() => setCancelModal(true)}
												>
													Cancel Subscription
												</Button>
											) : (
												<Button
													className="btn-default btn-sm"
													onClick={() => handleSubscribe()}
													disabled={actionLoading}
												>
													{actionLoading ? "Subscribing.." : "Subscribe"}
												</Button>
											)}
										</Col> */}
											</Row>
										)}
								</div>
								{userProfile?.subscription &&
									userProfile?.subscription.status === "active" && (
										<div className="rounded-4 bg-white mb-30 p-20">
											<div className="mb-20">
												<span className="text-medium-inter text-lg">
													Usage Summary
												</span>
												<p className="opacity-5 text-sm m-0">
													These are the current charges for this billing period.
												</p>
											</div>
											<div>
												{userProfile?.subscription?.items.map((item, index) => (
													<div
														key={index}
														className="account-usage-item flex-row-left"
													>
														<div className="col-6">
															{item.max_free ? (
																<span className="text-sm text-medium-inter">
																	{item.name} ({item.quantity}/{item.max_free})
																</span>
															) : (
																<span className="text-sm text-medium-inter">
																	{item.name} ({item.quantity})
																</span>
															)}
														</div>
														<div className="col-6 align-right">
															{item.max_free ? (
																item.max_free < item.quantity ? (
																	<span className="text-sm">
																		$
																		{(
																			(item.quantity - item.max_free) *
																			item.unit_amount
																		).toFixed(2)}
																	</span>
																) : (
																	<span className="text-sm">0.00</span>
																)
															) : (
																<span className="text-sm">
																	$
																	{(item.quantity * item.unit_amount).toFixed(
																		2
																	)}
																</span>
															)}
														</div>
													</div>
												))}
												<div className="account-usage-item flex-row-left">
													<div className="col-10 align-right">
														<span className="text-sm text-bold-inter">
															Total
														</span>
													</div>
													<div className="col-2 align-right">
														<span className="text-sm text-bold-inter">
															${grandTotal.toFixed(2)}
														</span>
													</div>
												</div>
											</div>
										</div>
									)}
								{paymentMethods?.data?.map((method, index) => (
									<div className="rounded-4 bg-white mb-30 p-20">
										<div className="mb-20">
											<span className="text-medium-inter text-lg">
												Payment Methods
											</span>
										</div>
										<div>
											<div
												key={index}
												className="rounded-4 p-20 border flex-row-left"
											>
												<div className="col-6">
													<span className="text-sm">
														{method.card.brand.charAt(0).toUpperCase() +
															method.card.brand.slice(1).toLowerCase()}{" "}
														ending in {method.card.last4}
													</span>
												</div>
												<div className="col-4">
													<span className="text-sm">
														Expires {method.card.exp_month}/
														{method.card.exp_year}
													</span>
												</div>
												<div className="settings-control col-2 flex-row-right">
													<Button className="flex-column">
														<i className="ri-settings-line text-sm" />
													</Button>
												</div>
											</div>
										</div>
									</div>
								))}
							</CustomTabPanel>
						</Col>
					</Row>
				</Container>
			</div>
			<Modal
				show={emailModal}
				className="post-modal"
				onHide={closeEmailModal}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Change Email</span>
					</div>
				</Modal.Header>
				<Form onSubmit={changeEmail}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>New Email</Form.Label>
								<Form.Control
									type="email"
									className={`form-input ${emailError && "error"}`}
									placeholder="Enter new email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<div className="full align-left">
									<span className="text-red label-md">
										{emailError && emailError}
									</span>
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${passwordError && "error"}`}
									placeholder="Enter your password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="full align-left">
									{passwordError ? (
										<p className="mb-0 mt-2 text-red label-md">
											{passwordError}
										</p>
									) : (
										<p className="mb-0 mt-2 label-md opacity-5">
											Before changing your email, please enter your password.
										</p>
									)}
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeEmailModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Change Email"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={nameModal}
				className="post-modal"
				onHide={closeNameModal}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Change Name</span>
					</div>
				</Modal.Header>
				<Form onSubmit={changeName}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>First Name</Form.Label>
								<Form.Control
									required
									type="text"
									className={`form-input ${firstNameError && "error"}`}
									placeholder="Enter first name"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
								<div className="full align-left">
									<span className="text-red label-md">
										{firstNameError && firstNameError}
									</span>
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									required
									type="text"
									className={`form-input ${lastNameError && "error"}`}
									placeholder="Enter last name"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
								<div className="full align-left">
									<span className="text-red label-md">
										{lastNameError && lastNameError}
									</span>
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeNameModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Change Name"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={msisdnModal}
				className="post-modal"
				onHide={closeMsisdnModal}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Change Mobile No.</span>
					</div>
				</Modal.Header>
				<Form onSubmit={changeMsisdn}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Mobile No.</Form.Label>
								<Form.Control
									required
									type="text"
									className={`form-input ${msisdnError && "error"}`}
									placeholder="Enter mobile no."
									value={msisdn}
									onChange={(e) => setMsisdn(e.target.value)}
								/>
								<div className="full align-left">
									<span className="text-red label-md">
										{msisdnError && msisdnError}
									</span>
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeMsisdnModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Change Name"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={usernameModal}
				className="post-modal"
				onHide={closeUsernameModal}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Change Username</span>
					</div>
				</Modal.Header>
				<Form onSubmit={changeUsername}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>New Username</Form.Label>
								<Form.Control
									required
									type="text"
									className={`form-input ${usernameError && "error"}`}
									placeholder="Enter new username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
								/>
								<div className="full align-left">
									<span className="text-red label-md">
										{usernameError && usernameError}
									</span>
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Password</Form.Label>
								<Form.Control
									required
									type="password"
									className={`form-input ${namePasswordError && "error"}`}
									placeholder="Enter your password"
									value={namePassword}
									onChange={(e) => setNamePassword(e.target.value)}
								/>
								<div className="full align-left">
									{namePasswordError ? (
										<p className="mb-0 mt-2 text-red label-md">
											{namePasswordError}
										</p>
									) : (
										<p className="mb-0 mt-2 label-md opacity-5">
											Before changing your username, please enter your password.
										</p>
									)}
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeUsernameModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Change Email"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={passwordResetModal}
				onHide={() => setPasswordResetModal(false)}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Change Password</h2>
					<p className="text-sm opacity-5 mb-0">
						We'll send a password reset link to {userProfile?.email}, would you
						like to proceed?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setPasswordResetModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={changePassword}
					>
						{actionLoading ? "Sending.." : "Send Reset Link"}
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={enableMfaPasswordModal}
				className="post-modal"
				onHide={() => closeEnableMfaPasswordModal()}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Confirm Password</span>
					</div>
				</Modal.Header>
				<Form onSubmit={confirmPasswordEnable}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${passwordError && "error"}`}
									placeholder="Enter your password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="full align-left">
									{passwordError ? (
										<span className="text-red label-md">{passwordError}</span>
									) : (
										<p className="mb-0 mt-2 label-md opacity-5">
											To get started, enter your password to confirm it’s really
											you.
										</p>
									)}
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => closeEnableMfaPasswordModal()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Confirming.." : "Confirm"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			<Modal
				show={disableMfaPasswordModal}
				className="post-modal"
				onHide={() => closeDisableMfaPasswordModal()}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Confirm Password</span>
					</div>
				</Modal.Header>
				<Form onSubmit={confirmPasswordDisable}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									className={`form-input ${passwordError && "error"}`}
									placeholder="Enter your password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div className="full align-left">
									{passwordError ? (
										<span className="text-red label-md">{passwordError}</span>
									) : (
										<p className="mb-0 mt-2 label-md opacity-5">
											To continue, enter your password to confirm it’s really
											you.
										</p>
									)}
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => closeDisableMfaPasswordModal()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Confirming.." : "Confirm"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			<Modal
				show={mfaPickerModal}
				className="post-modal"
				onHide={() => setMfaPickerModal(false)}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Enable Two-Factor Authentication
						</span>
						<p className="m-0 text-sm opacity-5">
							How would you like us to send your security code?
						</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Row className="form-block-sm">
						<Col>
							<Form.Check
								type="radio"
								label="Email"
								name="mfa-type"
								id="email-radio"
								onClick={() => setMfaType("email")}
							/>
						</Col>
					</Row>
					<Row className="form-block-sm">
						<Col>
							<Form.Check
								type="radio"
								label="Authenticator App"
								name="mfa-type"
								id="authenticator-radio"
								onClick={() => setMfaType("authenticator")}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setMfaPickerModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						disabled={actionLoading || !mfaType}
						onClick={enableMfa}
					>
						{actionLoading ? "Loading.." : "Continue"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={mfaSuccessModal}
				onHide={() => setMfaSuccessModal(false)}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">
						Your two-factor authentication is enabled
					</h2>
					<p className="text-sm opacity-5">
						You’ve just enabled two-factor authentication on your SMSafe
						account.
					</p>
					<p className="text-sm opacity-5 mb-0">
						Your account is now protected with an extra layer of security that
						requires both your password and an authentication code.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						onClick={() => setMfaSuccessModal(false)}
					>
						Continue
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={mfaQrModal}
				className="post-modal"
				onHide={() => setMfaQrModal(false)}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Setup Your Authenticator App
						</span>
						<p className="m-0 text-sm opacity-5">
							Scan the QR code below in your authenticator app.
						</p>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="flex-column modal-qr-container">
						<QRCode value={mfaLink} className="modal-qr" />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setMfaQrModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						onClick={() => {
							setMfaQrModal(false);
							setMfaCodeModal(true);
						}}
					>
						Continue
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={mfaCodeModal}
				className="post-modal"
				onHide={() => setMfaCodeModal(false)}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Enter Confirmation Code
						</span>
						<p className="m-0 text-sm opacity-5">
							Please enter the code in your authenticator app.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={confirmCode}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Code</Form.Label>
								<Form.Control
									className={`form-input ${codeError && "error"}`}
									placeholder="Enter confirmation code"
									value={confirmationCode}
									onChange={(e) => setConfirmationCode(e.target.value)}
								/>
								<div className="full align-left">
									<span className="text-red label-md">
										{codeError && codeError}
									</span>
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => setMfaCodeModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Confirming.." : "Confirm"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={cancelModal} onHide={closeCancelModal} centered>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Cancel Subscription
						</span>
					</div>
				</Modal.Header>
				<Form onSubmit={cancelSubscription}>
					<Modal.Body>
						<p className="text-sm text-light mb-20">
							You will lose access to the safes you own on{" "}
							{subscription && (
								<span className="text-medium-inter text-default">
									{format(
										new Date(subscription.current_period_end * 1000),
										"MMMM dd, yyyy"
									)}
								</span>
							)}
							{". "}
							They will be{" "}
							<span className="text-medium-inter text-default">
								permanently deleted
							</span>{" "}
							after 30 days if you don't reactivate your subscription.
						</p>
						<Form.Control
							type="password"
							placeholder="Enter your password"
							className={`form-input ${cancelPasswordError && "error"}`}
							value={cancelPassword}
							onChange={(e) => setCancelPassword(e.target.value)}
						/>
						<div className="full align-left">
							{cancelPasswordError ? (
								<p className="mb-0 mt-2 text-red label-md">
									{cancelPasswordError}
								</p>
							) : (
								<p className="mb-0 mt-2 label-md opacity-5">
									Before cancelling your subscription, please enter your
									password.
								</p>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							className="modal-btn btn-sm btn-danger text-medium-inter text-sm"
							disabled={!cancelPassword || actionLoading}
						>
							{actionLoading ? "Cancelling.." : "Cancel Subscription"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deactivateModal} onHide={closeDeactivateModal} centered>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Deactivate Account
						</span>
					</div>
				</Modal.Header>
				<Form onSubmit={deactivateAccount}>
					<Modal.Body>
						<p className="text-sm text-light mb-20">
							Messaging and sharing for safes you own will be instantly
							deactivated. Would you like to proceed?
						</p>
						<Form.Control
							type="password"
							placeholder="Enter your password"
							className={`form-input ${deactivatePasswordError && "error"}`}
							value={deactivatePassword}
							onChange={(e) => setDeactivatePassword(e.target.value)}
						/>
						<div className="full align-left">
							{deactivatePasswordError ? (
								<p className="mb-0 mt-2 text-red label-md">
									{deactivatePasswordError}
								</p>
							) : (
								<p className="mb-0 mt-2 label-md opacity-5">
									Before deactivating your account, please enter your password.
								</p>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							className="modal-btn btn-sm btn-danger text-medium-inter text-sm"
							disabled={!deactivatePassword || actionLoading}
						>
							{actionLoading ? "Deactivating.." : "Deactivate Account"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={closeDeleteModal} centered>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Delete Account</span>
					</div>
				</Modal.Header>
				<Form onSubmit={deleteAccount}>
					<Modal.Body>
						<p className="text-sm text-light mb-20">
							All your safes, mobile numbers, and 2FA will be deleted. This
							action cannot be undone, would you like to proceed?
						</p>
						<Form.Control
							type="password"
							placeholder="Enter your password"
							className={`form-input ${deletePasswordError && "error"}`}
							value={deletePassword}
							onChange={(e) => setDeletePassword(e.target.value)}
						/>
						<div className="full align-left">
							{deletePasswordError ? (
								<p className="mb-0 mt-2 text-red label-md">
									{deletePasswordError}
								</p>
							) : (
								<p className="mb-0 mt-2 label-md opacity-5">
									Before deleting your account, please enter your password.
								</p>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="submit"
							className="modal-btn btn-sm btn-danger text-medium-inter text-sm"
							disabled={!deletePassword || actionLoading}
						>
							{actionLoading ? "Deleting.." : "Delete Account"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={unsubscribeModal}
				className="post-modal"
				onHide={() => setUnsubscribeModal(false)}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Unsubscribe</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<p className="text-sm opacity-7">
						Are you sure you want to unsubscribe?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setUnsubscribeModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="modal-btn btn-md text-medium-inter text-sm"
						disabled={actionLoading}
					>
						{actionLoading ? "Saving.." : "Unsubscribe"}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Settings;

import { useState, useContext, useRef, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { KeyContext } from "../context/KeyContext";
import { SaltContext } from "../context/SaltContext";
import { WithContext as ReactTags } from "react-tag-input";
import { useSnackbar } from "react-simple-snackbar";
import QRCodeReader from "qrcode-reader";
import jsQR from "jsqr";
import Select, { components } from "react-select";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/esm/Modal";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Logo from "../components/LogoDark";
import Papa from "papaparse";
import {
	encrypt,
	decrypt,
	getImportFields,
	encryptBatch,
} from "../components/Utils";

const Onboarding = () => {
	const fileRef = useRef(null);
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [key] = useContext(KeyContext);
	const [salt] = useContext(SaltContext);
	const [safeId, setSafeId] = useState(null);
	const [finished, setFinished] = useState(false);
	const [step, setStep] = useState(
		new URLSearchParams(window.location.search).get("step") || "0"
	);
	const [actionLoading, setActionLoading] = useState(false);
	const [showNav, setShowNav] = useState(false);

	const [safeName, setSafeName] = useState(
		new URLSearchParams(window.location.search).get("safe") || ""
	);
	const [countryCode, setCountryCode] = useState("");
	const [emails, setEmails] = useState([]);
	const [assetErrors, setAssetErrors] = useState([]);
	const [showWifiPassword, setShowWifiPassword] = useState(false);

	const [assetName, setAssetName] = useState("");
	const [assetType, setAssetType] = useState("Login");
	const [assetUrl, setAssetUrl] = useState("");
	const [assetNotes, setAssetNotes] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [assetCode, setAssetCode] = useState("");
	const [assetMsisdn, setAssetMsisdn] = useState("");
	const [cardNo, setCardNo] = useState("");
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [cardName, setCardName] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [apiSecret, setApiSecret] = useState("");
	const [docs, setDocs] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [wifiEncryption, setWifiEncryption] = useState("WPA2");
	const [wifiName, setWifiName] = useState("");
	const [wifiPassword, setWifiPassword] = useState("");
	const [wifiIsHidden, setWifiIsHidden] = useState(false);

	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMsisdn, setContactMsisdn] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showAssetCode, setShowAssetCode] = useState(false);
	const [assetModal, setAssetModal] = useState(false);
	const [assetLoading, setAssetLoading] = useState(false);
	const [importModal, setImportModal] = useState(false);

	const [provider, setProvider] = useState("Apple");
	const [importFile, setImportFile] = useState(null);
	const [importLoading, setImportLoading] = useState(false);

	const requiredFields = {
		Login: [
			{ key: "assetName", value: assetName },
			{ key: "username", value: username },
			{ key: "password", value: password },
		],
		MFA: [
			{ key: "assetName", value: assetName },
			{ key: "assetCode", value: assetCode },
		],
		Card: [
			{ key: "assetName", value: assetName },
			{ key: "cardNo", value: cardNo },
			{ key: "cardExpiry", value: cardExpiry },
			{ key: "cardCode", value: cardCode },
			{ key: "cardName", value: cardName },
		],
		Address: [
			{ key: "assetName", value: assetName },
			{ key: "street", value: street },
			{ key: "city", value: city },
			{ key: "province", value: province },
			{ key: "country", value: country },
			{ key: "postalCode", value: postalCode },
		],
		API: [
			{ key: "assetName", value: assetName },
			{ key: "apiKey", value: apiKey },
		],
		Contact: [{ key: "assetName", value: assetName }],
	};

	const countryOptions = [
		{
			value: "US",
			label: "(+1) United States - $6/month",
			img: "https://flagcdn.com/h120/us.png",
		},
		{
			value: "CA",
			label: "(+1) Canada - $6/month",
			img: "https://flagcdn.com/h120/ca.png",
		},
	];

	const { Option } = components;
	const IconOption = (props) => (
		<Option {...props}>
			<div className="flex-row-left">
				<img
					src={props.data.img}
					alt={props.data.label}
					className="country-icon"
				/>
				<span className="text-sm">{props.data.label}</span>
			</div>
		</Option>
	);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleStep = (nextStep) => {
		setStep(nextStep);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
		space: 32,
	};

	const emailDelimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

	const handleDeleteEmail = (i) => {
		setEmails(emails.filter((tag, index) => index !== i));
	};

	const handleNewEmail = (tag) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(tag.text)) {
			if (emails.length < profile?.user.max_members - 1) {
				setEmails([...emails, tag]);
			} else {
				dangerSnackbar("Maximum members reached.");
			}
		} else {
			dangerSnackbar("Invalid email format.");
		}
	};

	const handleEmailBlur = (tag) => {
		if (tag) {
			handleNewEmail({ id: tag, text: tag });
		}
	};

	const onboardUser = async (encryptedData) => {
		var errors = [];
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: safeName,
				member_emails: emails,
				local_code: countryCode.value,
				asset: {
					name: assetName,
					type: assetType,
					url: assetUrl,
					login_type: "username",
					notes: encryptedData.assetNotes,
					username: encryptedData.username,
					password: encryptedData.password,
					msisdn: encryptedData.assetMsisdn,
					card_no: encryptedData.cardNo,
					expiration: encryptedData.cardExpiry,
					cvv: encryptedData.cardCode,
					card_name: encryptedData.cardName,
					api_key: encryptedData.apiKey,
					api_secret: encryptedData.apiSecret,
					documentation: encryptedData.docs,
					street: encryptedData.street,
					city: encryptedData.city,
					province: encryptedData.province,
					country: encryptedData.country,
					postal_code: encryptedData.postalCode,
					contact_name: encryptedData.contactName,
					contact_email: encryptedData.contactEmail,
					contact_msisdn: encryptedData.contactMsisdn,
					wifi_name: encryptedData.wifiName,
					wifi_password: encryptedData.wifiPassword,
					wifi_encryption: encryptedData.wifiEncryption,
					token: assetCode,
					wifi_is_hidden: wifiIsHidden,
				},
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/set-up`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			errors.push(data.error);
			setAssetErrors(errors);
		} else {
			setSafeId(data.group_id);
			console.log(data.group_id);
			setFinished(true);
		}
		setActionLoading(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		if (assetName) {
			requiredFields[assetType].map((field) => {
				if (!field.value || field.value === "" || field.value.length === 0) {
					newErrors.push(field.key);
				}
			});

			if (newErrors.length !== 0) {
				dangerSnackbar("Please fill out all required fields.");
				setActionLoading(false);
				setAssetErrors(newErrors);
				return;
			}
		}
		setAssetErrors(newErrors);
		encryptAssetData(onboardUser);
	};

	const handleAssetSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		requiredFields[assetType].map((field) => {
			if (!field.value || field.value === "" || field.value.length === 0) {
				newErrors.push(field.key);
			}
		});

		if (newErrors.length !== 0) {
			dangerSnackbar("Please fill out all required fields.");
			setActionLoading(false);
			setAssetErrors(newErrors);
			return;
		}

		if (newErrors.length !== 0) {
			dangerSnackbar("Please fill out all required fields.");
			setActionLoading(false);
			setAssetErrors(newErrors);
			return;
		}
		encryptAssetData(addAsset);
	};

	const addAsset = async (encryptedData) => {
		var errors = [];

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: assetName,
				type: assetType,
				url: assetUrl,
				notes: encryptedData.assetNotes,
				username: encryptedData.username,
				password: encryptedData.password,
				msisdn: encryptedData.assetMsisdn,
				card_no: encryptedData.cardNo,
				expiration: encryptedData.cardExpiry,
				cvv: encryptedData.cardCode,
				card_name: encryptedData.cardName,
				api_key: encryptedData.apiKey,
				api_secret: encryptedData.apiSecret,
				documentation: encryptedData.docs,
				street: encryptedData.street,
				city: encryptedData.city,
				province: encryptedData.province,
				country: encryptedData.country,
				postal_code: encryptedData.postalCode,
				contact_name: encryptedData.contactName,
				contact_email: encryptedData.contactEmail,
				contact_msisdn: encryptedData.contactMsisdn,
				wifi_name: encryptedData.wifiName,
				wifi_password: encryptedData.wifiPassword,
				wifi_encryption: encryptedData.wifiEncryption,
				private_key: encryptedData.privateKey,
				seed_phrase: encryptedData.seedPhrase,
				token: assetCode,
				wifi_is_hidden: wifiIsHidden,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${safeId}/assets`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			errors.push(data.error);
			setAssetErrors(errors);
		} else {
			defaultSnackbar(data.msg);
			navigate("/");
		}
		setActionLoading(false);
	};

	const copyAssetItem = async (asset_id, value) => {
		navigator.clipboard.writeText(value);
		defaultSnackbar("Copied to clipboard.");
	};

	const generatePassword = () => {
		const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const specialCharacters = '!@#$%&*()_"';

		const allCharacters =
			lowercaseLetters + uppercaseLetters + numbers + specialCharacters;

		let password = "";
		for (let i = 0; i < 16; i++) {
			// generating a 12-character password
			const randomIndex = Math.floor(Math.random() * allCharacters.length);
			password += allCharacters[randomIndex];
		}

		setPassword(password);
		setShowPassword(true);
	};

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		const qrCodeReader = new QRCodeReader();

		// Read the image file using FileReader
		const reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.src = reader.result;
			img.onload = () => {
				// Decode the QR code from the image
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = img.width;
				canvas.height = img.height;
				context.drawImage(img, 0, 0);

				const imageData = context.getImageData(
					0,
					0,
					canvas.width,
					canvas.height
				);

				// Decode the QR code using jsQR
				const code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					const params = code.data.split("&");
					let secret = "";
					params.forEach((param) => {
						if (param.includes("secret=")) {
							secret = param.split("=")[1];
						}
					});
					setAssetCode(secret);
				} else {
					dangerSnackbar("QR code not detected.");
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const handleCardNoChange = (e) => {
		let formattedCardNo = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardNo = formattedCardNo.replace(/(\d{4})/g, "$1 ").trim(); // Add space after every 4 characters

		setCardNo(formattedCardNo);
	};

	const handleCardExpiryChange = (e) => {
		let formattedCardExpiry = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardExpiry = formattedCardExpiry
			.replace(/^(\d{2})(\d{1,2})$/, "$1/$2") // Add '/' after first 2 characters if not already added
			.replace(/^(\d{2})\/(\d{2})$/, "$1/$2"); // Add '/' after next 2 characters if not already added

		setCardExpiry(formattedCardExpiry);
	};

	const handleAssetTypeChange = () => {
		setAssetUrl("");
		setUsername("");
		setPassword("");
		setCardNo("");
		setCardExpiry("");
		setCardCode("");
		setCardName("");
		setApiKey("");
		setApiSecret("");
		setDocs("");
		setAssetNotes("");
		setShowPassword(false);
	};

	const encryptAssetData = (callback) => {
		const encryptedData = {};
		const assetPassword = decrypt(key, profile.user.email, salt);

		encryptedData.assetNotes = encrypt(assetNotes, assetPassword, salt);
		encryptedData.username = encrypt(username, assetPassword, salt);
		encryptedData.password = encrypt(password, assetPassword, salt);
		encryptedData.assetMsisdn = encrypt(assetMsisdn, assetPassword, salt);
		encryptedData.cardNo = encrypt(cardNo, assetPassword, salt);
		encryptedData.cardExpiry = encrypt(cardExpiry, assetPassword, salt);
		encryptedData.cardCode = encrypt(cardCode, assetPassword, salt);
		encryptedData.cardName = encrypt(cardName, assetPassword, salt);
		encryptedData.apiKey = encrypt(apiKey, assetPassword, salt);
		encryptedData.apiSecret = encrypt(apiSecret, assetPassword, salt);
		encryptedData.docs = encrypt(docs, assetPassword, salt);
		encryptedData.street = encrypt(street, assetPassword, salt);
		encryptedData.city = encrypt(city, assetPassword, salt);
		encryptedData.province = encrypt(province, assetPassword, salt);
		encryptedData.country = encrypt(country, assetPassword, salt);
		encryptedData.postalCode = encrypt(postalCode, assetPassword, salt);
		encryptedData.contactName = encrypt(contactName, assetPassword, salt);
		encryptedData.contactEmail = encrypt(contactEmail, assetPassword, salt);
		encryptedData.contactMsisdn = encrypt(contactMsisdn, assetPassword, salt);
		encryptedData.wifiName = encrypt(wifiName, assetPassword, salt);
		encryptedData.wifiPassword = encrypt(wifiPassword, assetPassword, salt);
		encryptedData.wifiEncryption = encrypt(wifiEncryption, assetPassword, salt);

		callback(encryptedData);
	};

	const closeAssetModal = () => {
		setAssetModal(false);
		setAssetName("");
		setAssetType("Login");
		setAssetUrl("");
		setUsername("");
		setPassword("");
		setAssetCode("");
		setCardNo("");
		setCardExpiry("");
		setCardCode("");
		setCardName("");
		setApiKey("");
		setApiSecret("");
		setDocs("");
		setAssetNotes("");
		setStreet("");
		setCity("");
		setProvince("");
		setCountry("");
		setPostalCode("");
		setContactName("");
		setContactEmail("");
		setContactMsisdn("");
		setShowAssetCode(false);
		setShowPassword(false);
		setAssetErrors([]);
		setWifiEncryption("WPA2");
		setWifiName("");
		setWifiPassword("");
		setWifiIsHidden(false);
		setShowWifiPassword(false);
	};

	const processImportItems = async (salt, items) => {
		const assetPassword = decrypt(key, profile.user.email, salt);

		const encryptField = (text) => encryptBatch(text, assetPassword, salt);

		const newImportItems = await Promise.all(
			items.map(async (item, index) => {
				const fields = getImportFields(item, provider);

				if (fields) {
					return {
						type: fields.type,
						name: fields.name,
						url: fields.url,
						username: await encryptField(fields.username),
						password: await encryptField(fields.password),
						otp: await encryptField(fields.otp),
						card_no: await encryptField(fields.card_no),
						expiration: await encryptField(fields.expiration),
						cvv: await encryptField(fields.cvv),
						card_name: await encryptField(fields.card_name),
						msisdn: await encryptField(item.msisdn),
						api_key: await encryptField(item.api_key),
						api_secret: await encryptField(item.api_secret),
						documentation: await encryptField(item.documentation),
						street: await encryptField(item.street),
						city: await encryptField(item.city),
						province: await encryptField(item.province),
						country: await encryptField(item.country),
						postal_code: await encryptField(item.postal_code),
						contact_name: await encryptField(item.contact_name),
						contact_email: await encryptField(item.contact_email),
						contact_msisdn: await encryptField(item.contact_msisdn),
						notes: await encryptField(item.notes),
					};
				}
				return null;
			})
		);

		importAssets(newImportItems);
	};

	const importAssets = async (importItems) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${safeId}/assets/import`,
			{
				method: "POST",
				headers: {
					"X-Auth-Token": user ? user.session_token : "",
				},
				body: JSON.stringify({
					items: importItems,
				}),
			}
		);

		if (!response.ok) {
			dangerSnackbar("Failed to upload file.");
		} else {
			defaultSnackbar("Assets imported.");
			navigate(`/safes/${safeId}`);
		}
		setImportLoading(false);
		setImportModal(false);
	};

	const readImportFile = (e) => {
		e.preventDefault();
		setImportLoading(true);
		if (!importFile) {
			dangerSnackbar("Please select a file.");
			return;
		}

		const reader = new FileReader();
		const fileType = importFile.type;

		reader.onload = (e) => {
			try {
				if (fileType === "application/json") {
					// Handle JSON files
					const jsonData = JSON.parse(e.target.result);

					if (jsonData.items && Array.isArray(jsonData.items)) {
						getImportSalt(jsonData.items);
					} else {
						dangerSnackbar("Invalid JSON file.");
					}
				} else if (fileType === "text/csv") {
					// Handle CSV files
					Papa.parse(e.target.result, {
						header: true,
						skipEmptyLines: true,
						complete: (results) => {
							if (results.data.length > 0) {
								getImportSalt(results.data);
							} else {
								dangerSnackbar("Invalid CSV file.");
							}
						},
						error: () => {
							dangerSnackbar("Invalid CSV file.");
						},
					});
				} else {
					dangerSnackbar("Unsupported file type.");
				}
			} catch (error) {
				dangerSnackbar("Invalid JSON file.");
			}
		};
		reader.readAsText(importFile);
	};

	const getImportSalt = async (items) => {
		setImportLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/salt`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			setImportLoading(false);
		} else {
			processImportItems(data.salt, items);
		}
	};

	const handleFileChange = (e) => {
		setImportFile(e.target.files[0]);
	};

	useMemo(() => {
		setAssetErrors([]);
		handleAssetTypeChange();
	}, [assetType]);

	useEffect(() => {
		if (profile && !profile.user.email_verified) {
			navigate("/");
		}
	}, [profile]);

	return (
		<>
			<div className="onboarding-wizard full-page">
				<div className="login-header full flex-row-left bg-white mb-50">
					<Container>
						<Row className="flex-row-left">
							<Col>
								<Link to="/">
									<Logo variant="dark" className="nav-logo" />
								</Link>
							</Col>
							<Col className="nav-action-container p-0 flex-row-right">
								<div className="nav-lg content-lg flex-row-right">
									<p className="text-light mb-0">
										<Link
											to="/dashboard"
											className="text-blue text-medium-inter"
										>
											Go to dashboard
										</Link>
									</p>
								</div>
								<div className="content-sm flex-row-right">
									<div
										className="nav-toggle flex-column"
										onClick={() => setShowNav(!showNav)}
									>
										<div className={`fries ${showNav && "active"}`}>
											<div className="top-fry" />
											<div className="mid-fry" />
											<div className="bottom-fry" />
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div
					className={`nav-sm login bg-white border-top ${showNav && "active"}`}
				>
					<Container className="full-height pt-4 pb-4">
						<Link to="/dashboard" className="text-blue text-medium-inter">
							Go to dashboard
						</Link>
					</Container>
				</div>
				<Container className="flex-column">
					{!finished ? (
						<div className="onboarding-container col-lg-5 col-md-7 col-12">
							<span className="text-medium-inter title-md">
								SMSafe {profile?.user.tier}
							</span>
							<p className="text-sm mb-30">
								Let's get you started by setting up your first safe.
							</p>
							<Accordion activeKey={step}>
								<Accordion.Item eventKey="0" className="onboarding-item">
									<Accordion.Header
										className="onboarding-step-header"
										onClick={() => handleStep("0")}
									>
										<div className="flex-row-left text-default">
											<div className="onboarding-header-icon flex-column">
												<span className="text-medium-inter text-sm">1</span>
											</div>
											<span className="text-medium-inter">
												Create a brand new safe
											</span>
										</div>
									</Accordion.Header>
									<Accordion.Body className="onboarding-item-body">
										<p className="text-sm opacity-7 mb-20">
											A safe is a secure place where you can store assets like
											login credentials,card details, 2FA codes, and more.
										</p>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												handleStep("1");
											}}
										>
											<Row className="mb-30">
												<Col>
													<Form.Label>Safe Name</Form.Label>
													<Form.Control
														autoFocus
														className="form-input"
														value={safeName}
														onChange={(e) => setSafeName(e.target.value)}
													/>
												</Col>
											</Row>
											<Button
												type="submit"
												className="btn-default btn-md rounded-4 full flex-column mb-15"
												disabled={!safeName}
											>
												Create Safe
											</Button>
											<Link to="/">
												<Button className="btn-tertiary full btn-md text-sm rounded-4 full flex-column">
													Skip to dashboard
												</Button>
											</Link>
										</Form>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1" className="onboarding-item">
									<Accordion.Header
										className="onboarding-step-header"
										onClick={() => handleStep("1")}
									>
										<div className="flex-row-left text-default">
											<div className="onboarding-header-icon flex-column">
												<span className="text-medium-inter text-sm">2</span>
											</div>
											<span className="text-medium-inter">
												Invite members to your safe
											</span>
										</div>
									</Accordion.Header>
									<Accordion.Body className="onboarding-item-body">
										<p className="text-sm opacity-7 mb-10">
											You can invite team members to your safe so you can
											securely share assets with them.
										</p>
										<Form
											onSubmit={(e) => {
												e.preventDefault();
												handleStep("2");
											}}
										>
											<Row className="mb-30">
												<Col>
													<ReactTags
														tags={emails}
														delimiters={emailDelimiters}
														handleInputBlur={handleEmailBlur}
														handleDelete={handleDeleteEmail}
														handleAddition={handleNewEmail}
														inputFieldPosition="bottom"
														inputProps={{
															disabled:
																profile && profile?.user.max_members === 1,
														}}
														allowDragDrop={false}
														placeholder={
															profile && profile?.user.max_members !== 1
																? `Add upto ${
																		profile?.user.max_members - 1
																  } emails`
																: "Enter email addresses"
														}
														autofocus={false}
														classNames={{
															tagInput: "full",
															tagInputField:
																"full form-control form-input text-sm",
															tag: "tag-item text-sm bg-blue text-white rounded-4",
															remove: "tag-remove-btn text-white flex-column",
														}}
													/>
													{emails.length >= profile?.user.max_members - 1 && (
														<p className="text-sm mt-3">
															Need to invite more members? You can do so by{" "}
															<Link
																to={`/plans?redirect_url=/account-setup?safe=${safeName}%26step=${step}`}
																className="text-medium-inter text-blue"
															>
																upgrading your plan
															</Link>
															.
														</p>
													)}
												</Col>
											</Row>
											{profile && profile?.user.max_members === 1 ? (
												<Link
													to={`/plans?redirect_url=/account-setup?safe=${safeName}%26step=${step}`}
												>
													<Button className="btn-default btn-md rounded-4 full flex-row-center mb-15">
														<i className="ri-lock-2-line" />
														Unlock this feature
													</Button>
												</Link>
											) : (
												<Button
													type="submit"
													className="btn-default btn-md rounded-4 full flex-column mb-15"
												>
													Invite Members
												</Button>
											)}
											<Button
												className="btn-tertiary full btn-md text-sm rounded-4 full flex-column"
												onClick={() => handleStep("2")}
											>
												Skip this step
											</Button>
										</Form>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2" className="onboarding-item">
									<Accordion.Header
										className="onboarding-step-header"
										onClick={() => handleStep("2")}
									>
										<div className="flex-row-left text-default">
											<div className="onboarding-header-icon flex-column">
												<span className="text-medium-inter text-sm">3</span>
											</div>
											<span className="text-medium-inter">
												Get your free virtual number
											</span>
										</div>
									</Accordion.Header>
									<Accordion.Body className="onboarding-item-body">
										<Form onSubmit={handleSubmit}>
											<Row className="mb-30">
												<Col>
													<Form.Label>Country Code</Form.Label>
													<Select
														isDisabled={
															profile && profile?.user.max_msisdns === 0
														}
														defaultValue={countryCode}
														onChange={setCountryCode}
														options={countryOptions}
														components={{ Option: IconOption }}
													/>
												</Col>
											</Row>
											{profile && profile?.user.max_msisdns === 0 ? (
												<Link
													to={`/plans?redirect_url=/account-setup?safe=${safeName}%26step=${step}`}
												>
													<Button className="btn-default btn-md rounded-4 full flex-row-center mb-15">
														<i className="ri-lock-2-line" />
														Unlock this feature
													</Button>
												</Link>
											) : (
												<Button
													type="submit"
													className="btn-default btn-md rounded-4 full flex-column mb-15"
													disabled={!countryCode}
												>
													Get Number
												</Button>
											)}
											{safeName ? (
												<Button
													type="submit"
													className="btn-tertiary full btn-md text-sm rounded-4 full flex-column"
												>
													Skip this step
												</Button>
											) : (
												<Link to="/">
													<Button className="btn-tertiary full btn-md text-sm rounded-4 full flex-column">
														Skip this step
													</Button>
												</Link>
											)}
										</Form>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					) : (
						<div className="onboarding-container col-lg-5 col-md-7 col-12">
							<span className="text-medium-inter title-md">
								Congratulations!
							</span>
							<p className="text-sm mb-30">
								Your account is ready. What would you like to do next?
							</p>
							<Link to="/">
								<Button className="btn-default rounded-4 btn-lg text-sm full mb-15">
									Go to dashboard
								</Button>
							</Link>
							<Button
								className="btn-default-outline rounded-4 btn-lg text-sm full mb-15"
								onClick={() => setAssetModal(true)}
							>
								Create my first asset
							</Button>
							<Button
								className="btn-default-outline rounded-4 btn-lg text-sm full"
								onClick={() => setImportModal(true)}
							>
								Import passwords
							</Button>
						</div>
					)}
				</Container>
			</div>
			<Modal
				show={assetModal}
				size="lg"
				onHide={closeAssetModal}
				className="asset-modal"
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">New Asset</span>
						<p className="m-0 text-sm opacity-5">
							Securely store credentials, cards, and more.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={handleAssetSubmit}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Asset Type</Form.Label>
								<div>
									<div
										className={`asset-type-picker border ${
											assetType === "Login" && "active"
										}`}
										onClick={() => setAssetType("Login")}
									>
										<div className="flex-row-left">
											<i className="ri-user-line" />
											<span className="label-md">Login</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "MFA" && "active"
										}`}
										onClick={() => setAssetType("MFA")}
									>
										<div className="flex-row-left">
											<i className="ri-key-2-line" />
											<span className="label-md">2FA Code</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Card" && "active"
										}`}
										onClick={() => setAssetType("Card")}
									>
										<div className="flex-row-left">
											<i className="ri-bank-card-2-line" />
											<span className="label-md">Card</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Note" && "active"
										}`}
										onClick={() => setAssetType("Note")}
									>
										<div className="flex-row-left">
											<i className="ri-sticky-note-line" />
											<span className="label-md">Note</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Address" && "active"
										}`}
										onClick={() => setAssetType("Address")}
									>
										<div className="flex-row-left">
											<i className="ri-map-pin-2-line" />
											<span className="label-md">Address</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Contact" && "active"
										}`}
										onClick={() => setAssetType("Contact")}
									>
										<div className="flex-row-left">
											<i className="ri-user-line" />
											<span className="label-md">Contact</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "API" && "active"
										}`}
										onClick={() => setAssetType("API")}
									>
										<div className="flex-row-left">
											<i className="ri-code-s-slash-line" />
											<span className="label-md">API Credentials</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Wifi" && "active"
										}`}
										onClick={() => setAssetType("Wifi")}
									>
										<div className="flex-row-left">
											<i className="ri-wifi-line" />
											<span className="label-md">WiFi</span>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Asset Name*</Form.Label>
								<Form.Control
									type="text"
									className={`form-input input-lg text-sm rounded-4 ${
										assetErrors.includes("assetName") && "border-danger"
									}`}
									placeholder="Enter asset name."
									value={assetName}
									onChange={(e) => setAssetName(e.target.value)}
									autoComplete="off"
								/>
							</Col>
						</Row>
						{assetType === "Login" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											URL <span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<Form.Control
											type="text"
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetUrl") && "border-danger"
											}`}
											placeholder="Enter website URL."
											value={assetUrl}
											onChange={(e) => setAssetUrl(e.target.value)}
											autoComplete="off"
										/>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Username*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-username form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("username") && "border-danger"
												}`}
												placeholder="Enter username/email."
												value={username}
												onChange={(e) => setUsername(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, username)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Password*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type={showPassword ? "text" : "password"}
												className={`asset-password form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("password") && "border-danger"
												}`}
												placeholder="Enter password."
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => setShowPassword(!showPassword)}
												>
													<i
														className={`opacity-7 ${
															showPassword ? "ri-eye-off-line" : "ri-eye-line"
														}`}
													/>
												</Button>
												<Button
													variant="light flex-column"
													onClick={generatePassword}
												>
													<i className="ri-refresh-line opacity-7" />
												</Button>
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, password)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Authenticator Key (TOTP){" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-code form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("assetCode") && "border-danger"
												}`}
												placeholder="Enter 2FA setup key."
												value={assetCode}
												onChange={(e) => setAssetCode(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<input
													ref={fileRef}
													type="file"
													accept="image/png, image/jpeg"
													onChange={handleFileInputChange}
													className="hidden"
												/>
												<Button
													variant="light flex-column"
													onClick={() => fileRef.current.click()}
												>
													<i className="ri-qr-code-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Notes{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<Form.Control
											as="textarea"
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetNotes") && "border-danger"
											}`}
											placeholder="Add notes"
											value={assetNotes}
											onChange={(e) => setAssetNotes(e.target.value)}
											autoComplete="off"
										/>
									</Col>
								</Row>
							</>
						)}
						{assetType === "MFA" && (
							<Row className="form-block-sm">
								<Col>
									<Form.Label>Authenticator Key (TOTP)*</Form.Label>
									<div className="flex-row-left full">
										<Form.Control
											type="text"
											className={`asset-code form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetCode") && "border-danger"
											}`}
											placeholder="Enter 2FA setup key."
											value={assetCode}
											onChange={(e) => setAssetCode(e.target.value)}
											autoComplete="off"
										/>
										<div className="asset-pw-actions flex-row-right">
											<input
												ref={fileRef}
												type="file"
												accept="image/png, image/jpeg"
												onChange={handleFileInputChange}
												className="hidden"
											/>
											<Button
												variant="light flex-column"
												onClick={() => fileRef.current.click()}
											>
												<i className="ri-qr-code-line opacity-7" />
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						)}
						{assetType === "Card" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Card Number*</Form.Label>
										<div className="flex-row-left pull">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardNo") && "border-danger"
												}`}
												placeholder="Enter card number."
												value={cardNo}
												onChange={handleCardNoChange}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() =>
														copyAssetItem(null, cardNo.replace(/\s/g, ""))
													}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Expiration*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardExpiry") && "border-danger"
												}`}
												placeholder="Enter card expiration."
												value={cardExpiry}
												onChange={handleCardExpiryChange}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, cardExpiry)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
									<Col>
										<Form.Label>Security Code*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="password"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardCode") && "border-danger"
												}`}
												placeholder="Enter security code."
												value={cardCode}
												onChange={(e) => setCardCode(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, cardCode)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Name on Card*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardName") && "border-danger"
												}`}
												placeholder="Enter name on card."
												value={cardName}
												onChange={(e) => setCardName(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, cardName)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Address" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Street Address*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												as="textarea"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("street") && "border-danger"
												}`}
												placeholder="Enter street address."
												value={street}
												onChange={(e) => setStreet(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-textarea-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, street)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>City/Town*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("city") && "border-danger"
												}`}
												placeholder="Enter city/town."
												value={city}
												onChange={(e) => setCity(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, city)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
									<Col>
										<Form.Label>State/Province*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("province") && "border-danger"
												}`}
												placeholder="Enter state/province."
												value={province}
												onChange={(e) => setProvince(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, province)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Country*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("country") && "border-danger"
												}`}
												placeholder="Enter country."
												value={country}
												onChange={(e) => setCountry(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, country)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
									<Col>
										<Form.Label>Postal Code*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("postalCode") && "border-danger"
												}`}
												placeholder="Enter postal code."
												value={postalCode}
												onChange={(e) => setPostalCode(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, postalCode)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "API" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>API Key*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("apiKey") && "border-danger"
												}`}
												placeholder="Enter API key."
												value={apiKey}
												onChange={(e) => setApiKey(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, apiKey)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Secret{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("apiSecret") && "border-danger"
												}`}
												placeholder="Enter secret key."
												value={apiSecret}
												onChange={(e) => setApiSecret(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, apiSecret)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Documentation{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("docs") && "border-danger"
												}`}
												placeholder="Enter documentation URL."
												value={docs}
												onChange={(e) => setDocs(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Contact" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Contact Name*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("contactName") && "border-danger"
												}`}
												placeholder="Enter contact name."
												value={contactName}
												onChange={(e) => setContactName(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Email{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="email"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("contactEmail") &&
													"border-danger"
												}`}
												placeholder="Enter contact email."
												value={contactEmail}
												onChange={(e) => setContactEmail(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
									<Col>
										<Form.Label>
											Contact Number{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("contactMsisdn") &&
													"border-danger"
												}`}
												placeholder="Enter contact number."
												value={contactMsisdn}
												onChange={(e) => setContactMsisdn(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Wifi" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>SSID (Network Name)*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiName") && "border-danger"
												}`}
												placeholder="Enter contact number."
												value={wifiName}
												onChange={(e) => setWifiName(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Password*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type={showWifiPassword ? "text" : "password"}
												className={`asset-password form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiPassword") &&
													"border-danger"
												}`}
												placeholder="Enter wifi password."
												value={wifiPassword}
												onChange={(e) => setWifiPassword(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => setShowWifiPassword(!showWifiPassword)}
												>
													<i
														className={`opacity-7 ${
															showWifiPassword
																? "ri-eye-off-line"
																: "ri-eye-line"
														}`}
													/>
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Encryption Type*</Form.Label>
										<div className="flex-row-left full">
											<Form.Select
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiEncryption") &&
													"border-danger"
												}`}
												placeholder="Enter contact number."
												value={wifiEncryption}
												onChange={(e) => setWifiEncryption(e.target.value)}
											>
												<option value="WPA2">WPA2</option>
												<option value="WPA">WPA</option>
												<option value="nopass">No Password</option>
											</Form.Select>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Hidden Network*</Form.Label>
										<div className="flex-row-left full">
											<Form.Select
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiIsHidden") &&
													"border-danger"
												}`}
												placeholder="Enter contact number."
												value={wifiIsHidden}
												onChange={(e) => setWifiIsHidden(e.target.value)}
											>
												<option value={false}>No</option>
												<option value={true}>Yes</option>
											</Form.Select>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Note" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Note*</Form.Label>
										<Form.Control
											as="textarea"
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetNotes") && "border-danger"
											}`}
											placeholder="Add notes"
											value={assetNotes}
											onChange={(e) => setAssetNotes(e.target.value)}
											autoComplete="off"
										/>
									</Col>
								</Row>
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeAssetModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={importModal}
				onHide={() => {
					setImportModal(false);
					setImportFile(null);
				}}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Import Assets
				</Modal.Header>
				<Form onSubmit={readImportFile}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Provider</Form.Label>
								<Form.Select
									className="form-input rounded-4"
									value={provider}
									onChange={(e) => setProvider(e.target.value)}
								>
									<option value="Apple">Apple</option>
									<option value="Bitwarden">Bitwarden</option>
									<option value="LastPass">LastPass</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Import File</Form.Label>
								<Form.Control
									type="file"
									accept=".json,.csv"
									className="form-input rounded-4"
									onChange={handleFileChange}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
							onClick={() => {
								setImportModal(false);
								setImportFile(null);
							}}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-default btn-sm text-medium-inter text-sm"
							disabled={importLoading}
						>
							{importLoading ? "Importing.." : "Import"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Onboarding;

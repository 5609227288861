import CryptoJS from "crypto-js";
import format from "date-fns/format";

export const encrypt = (text, password, salt) => {
	if (!text || text === "") {
		return null;
	}

	const key = CryptoJS.PBKDF2(password, salt, {
		keySize: 256 / 32,
		iterations: 10000,
		hasher: CryptoJS.algo.SHA256,
	}).toString(CryptoJS.enc.Hex);

	const iv = CryptoJS.lib.WordArray.random(128 / 8);

	const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Hex.parse(key), {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	}).toString();

	return iv.toString(CryptoJS.enc.Hex) + encrypted;
};

export const encryptBatch = (text, password, salt) => {
	return new Promise((resolve, reject) => {
		try {
			if (!text || text === "") {
				resolve(null);
				return;
			}

			const key = CryptoJS.PBKDF2(password, salt, {
				keySize: 256 / 32,
				iterations: 10000,
				hasher: CryptoJS.algo.SHA256,
			}).toString(CryptoJS.enc.Hex);

			const iv = CryptoJS.lib.WordArray.random(128 / 8);

			const encrypted = CryptoJS.AES.encrypt(
				text,
				CryptoJS.enc.Hex.parse(key),
				{
					iv: iv,
					mode: CryptoJS.mode.CBC,
					padding: CryptoJS.pad.Pkcs7,
				}
			).toString();

			resolve(iv.toString(CryptoJS.enc.Hex) + encrypted);
		} catch (error) {
			reject(error);
		}
	});
};

export const decrypt = (ciphertext, password, salt) => {
	if (!ciphertext || ciphertext === "") {
		return null;
	}

	const key = CryptoJS.PBKDF2(password, salt, {
		keySize: 256 / 32,
		iterations: 10000,
		hasher: CryptoJS.algo.SHA256,
	}).toString(CryptoJS.enc.Hex);

	const iv = CryptoJS.enc.Hex.parse(ciphertext.slice(0, 32));

	const encryptedText = ciphertext.slice(32);

	const decrypted = CryptoJS.AES.decrypt(
		encryptedText,
		CryptoJS.enc.Hex.parse(key),
		{
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}
	);

	return decrypted.toString(CryptoJS.enc.Utf8);
};

export const getImportFields = (item, provider) => {
	if (provider === "Apple") {
		return {
			type: "Login",
			name: item?.Title || null,
			url: item?.URL || null,
			username: item?.Username || null,
			password: item?.Password || null,
		};
	}

	if (provider === "Bitwarden") {
		if (item.type === 1) {
			let url = null;
			if (item.login?.uris && item.login.uris[0]) {
				url = item.login.uris[0].uri;
			}

			return {
				type: "Login",
				name: item?.name || null,
				url: url,
				username: item.login?.username || null,
				password: item.login?.password || null,
			};
		}

		if (item.type === 3) {
			let expiration = null;
			if (item.card?.expMonth && item.card?.expYear) {
				expiration = `${item.card.expMonth.padStart(
					2,
					"0"
				)}/${item.card.expYear.slice(-2)}`;
			}

			return {
				type: "Card",
				name: item?.name || null,
				card_no: item.card?.number || null,
				expiration: expiration,
				cvv: item.card?.code || null,
				card_name: item.card?.cardholderName || null,
			};
		}
	}

	if (provider === "LastPass") {
		if (item.username && item.username !== "") {
			return {
				type: "Login",
				name: item?.name || null,
				url: item?.url || null,
				username: item?.username || null,
				password: item?.password || null,
			};
		}

		if (item.extra && item.extra !== "") {
			const fields = Object.fromEntries(
				item.extra
					.split("\n")
					.map((line) => line.split(":").map((part) => part.trim()))
					.filter((parts) => parts.length === 2)
			);

			if (fields.NoteType === "Credit Card") {
				const [expirationMonth, expirationYear] = fields[
					"Expiration Date"
				]?.split(",") || [null, null];
				const expiration =
					expirationMonth && expirationYear
						? `${expirationMonth.slice(0, 3)}/${expirationYear.slice(-2)}`
						: null;

				return {
					type: "Card",
					name: item?.name || null,
					card_no: fields["Number"] || null,
					expiration: expiration,
					cvv: fields["Security Code"] || null,
					card_name: fields["Name on Card"] || null,
				};
			}
		}
	}

	return null;
};

export const getAge = (datetime) => {
	let timeString;
	const now = new Date();
	const createdAt = new Date(datetime);
	const localCreatedAt = new Date(
		createdAt.getTime() - createdAt.getTimezoneOffset() * 60000
	);
	const diff = now - localCreatedAt;
	const diffInSeconds = Math.round(diff / 1000);
	const diffInMinutes = Math.round(diff / (1000 * 60));
	const diffInHours = Math.round(diff / (1000 * 60 * 60));

	if (diff < 86400000) {
		if (diffInSeconds < 60) {
			timeString = `${diffInSeconds} ${
				diffInSeconds === 1 ? "second" : "seconds"
			} ago`;
		} else if (diffInMinutes < 60) {
			timeString = `${diffInMinutes} ${
				diffInMinutes === 1 ? "minute" : "minutes"
			} ago`;
		} else {
			timeString = `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
		}
	} else if (diff < 172800000) {
		timeString = "Yesterday - " + format(new Date(datetime), "hh:mm a");
	} else if (diff < 31536000000) {
		timeString = format(new Date(datetime), "MMM dd - hh:mm a");
	} else {
		timeString = format(new Date(datetime), "MMM dd, yyyy - hh:mm a");
	}
	return timeString;
};

export const getCreditCardType = (cardNo) => {
	if (!cardNo) {
		return null;
	}

	// Remove spaces and dashes
	cardNo = cardNo.replace(/[\s-]/g, "");

	// Validate minimum length and numeric content
	if (cardNo.length < 14 || !/^\d+$/.test(cardNo)) {
		return null;
	}

	const firstOneDigit = parseInt(cardNo.substring(0, 1));
	const firstTwoDigits = parseInt(cardNo.substring(0, 2));
	const firstThreeDigits = parseInt(cardNo.substring(0, 3));
	const firstFourDigits = parseInt(cardNo.substring(0, 4));

	let cardName = "";

	// Check by first two digits using switch-case
	switch (firstTwoDigits) {
		case 34:
		case 37:
			cardName = "American Express";
			break; // Stop checking further cases
		case 36:
			cardName = "Diners Club";
			break;
		case 38:
			cardName = "Carte Blanche";
			break;
		case 51:
		case 52:
		case 53:
		case 54:
		case 55:
			cardName = "Master Card";
			break;
		default:
			// Check by first four digits if no match found
			switch (firstFourDigits) {
				case 2014:
				case 2149:
					cardName = "EnRoute";
					break;
				case 2131:
				case 1800:
					cardName = "JCB";
					break;
				case 6011:
					cardName = "Discover";
					break;
				default:
					// Check by first three digits
					if (firstThreeDigits >= 300 && firstThreeDigits <= 305) {
						cardName = "American Diners Club";
					}
					// Check by first one digit if no match found
					if (!cardName) {
						if (firstOneDigit === 3) {
							cardName = "JCB";
						} else if (firstOneDigit === 4) {
							cardName = "Visa";
						}
					}
					break;
			}
			break;
	}

	const cardData = [
		{ name: "American Express", website: "https://www.americanexpress.com" },
		{ name: "Diners Club", website: "https://www.dinersclub.com" },
		{ name: "Carte Blanche", website: "https://www.carteblanche.com" },
		{ name: "Discover", website: "https://www.discover.com" },
		{ name: "EnRoute", website: "https://www.enroute.com" },
		{ name: "JCB", website: "https://www.jcb.com" },
		{ name: "Master Card", website: "https://www.mastercard.com" },
		{ name: "Visa", website: "https://www.visa.com" },
	];

	const card = cardData.find((card) => card.name === cardName);
	return card || null;
};
